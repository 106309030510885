<template>
    <v-app class="authentication">
        <router-view />

        <c-snackbar />
        <loading />
    </v-app>
</template>

<script>
export default {
    components: {
        CSnackbar: () => import('@/components/common/CSnackbar'),
        Loading: () => import('@/components/common/Loading')
    }
};
</script>
